import { Stack, TableCell, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { OrganisationType, Site } from "../../types";

import TelehealthIconButton from "../telehealth-icon-button";
import SingleTextFieldForm from "../login/single-text-field-form";
import OrganisationColorSelect from "../organisation/organisation-color-select";
import OrganisationColorSelectWithConfirm from "../organisation/organisation-color-select-with-confirm";
import StandaloneOrganisationTypeSelect from "../organisation/standalone-organisation-type-select";

interface SiteTableRowProps {
    site: Site;
    canDelete: boolean;
    canUpdate: boolean;
    onDelete: (site: Site) => void;
    onUpdate: (site: Site) => void;
}

const SiteTableRow: React.FC<SiteTableRowProps> = ({
    site,
    canDelete,
    canUpdate,
    onDelete,
    onUpdate,
}) => {

    const nameUpdated = (siteName: string) => {
        onUpdate({ ...site, siteName });
    }

    const colorUpdated = (color: string) => {
        onUpdate({ ...site, color });
    }

    const typeUpdated = (siteType: OrganisationType) => {
        onUpdate({ ...site, siteType });
    }

    return (
        <>
            <TableCell align="left" width={"40%"}>
                {!canUpdate && site.siteName}
                {canUpdate &&
                    <SingleTextFieldForm
                        fieldName="siteName"
                        label="Site Name"
                        defaultValue={site.siteName}
                        onSubmit={nameUpdated} />}
            </TableCell>
            <TableCell align="left">
                {!canUpdate && site.siteType}
                {canUpdate &&
                    <StandaloneOrganisationTypeSelect selectedType={site.siteType} updateType={typeUpdated} />}
            </TableCell>
            <TableCell align="left">
                {!canUpdate &&
                    <Stack direction="row" spacing={1}
                        sx={{
                            backgroundColor: site.color,
                            width: 20,
                            height: 20,
                        }} />}
                {canUpdate &&
                    <OrganisationColorSelectWithConfirm selectedColor={site.color} colorChanged={colorUpdated} />
                }
            </TableCell>
            <TableCell align="right">
                <Stack direction="row" spacing={1} width={"100%"} justifyContent={"right"}>
                    <TelehealthIconButton
                        icon={<DeleteForeverIcon />}
                        color="error"
                        tooltip="Delete User"
                        inactiveTooltip="You do not have permission to delete this user"
                        disabled={!canDelete}
                        onClick={() => onDelete(site)} />
                </Stack>
            </TableCell>
        </>
    );
}

export default SiteTableRow;