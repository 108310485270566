import React from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";

import { useFormik } from "formik";

import { Site, OrganisationType, validField } from "../../types";

import SiteColorSelect, { findColor } from "../organisation/organisation-color-select";

interface EditSiteModalProps {
    updateSite: (Site: Site) => void;
    site: Site;
    create: boolean;
}

const EditSiteForm: React.FC<EditSiteModalProps> = ({
    site,
    updateSite,
    create,
}) => {

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            siteId: site.siteId,
            name: site.siteName,
            type: site.siteType,
            color: findColor(site.color),
        },
        validate: values => {
            const errors: Partial<Record<keyof typeof values, string>> = {};

            if (!validField(values.name)) {
                errors.name = "Name must not contain special characters";
            }

            if (values.name.length < 3) {
                errors.name = "Name must be at least 3 characters";
            }
            if (values.name.length > 32) {
                errors.name = "Name must be at most 32 characters";
            }

            return errors;
        },
        onSubmit: values => {
            const updatedSite: Site =
            {
                siteId: values.siteId,
                siteName: values.name,
                siteType: values.type,
                color: values.color,
                linkedOrganisations: site.linkedOrganisations,
            }

            updateSite(updatedSite);
            closeModal();
        }
    });


    const closeModal = () => {
        formik.resetForm();
    }

    const submitDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;

    // form component is required for formik to work
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                direction={"row"}
                container
                spacing={2}
                paddingY={1}
                justifyContent={"left"}
                alignItems={"center"}
                width={"100%"}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        variant="outlined"
                        autoComplete="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    {formik.touched.name && formik.errors.name &&
                        <Typography color="error">{formik.errors.name as string}</Typography>}
                </Grid>
                <Grid item xs={6}>
                    <SiteColorSelect selectedColor={formik.values.color} onSelectColor={formik.handleChange} />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="type-select-label" color="primary">
                            Type
                        </InputLabel>
                        <Select
                            name="type"
                            label="Type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            sx={{
                                minWidth: "160px",
                                height: "50px",
                            }}>
                            {
                                Object.values(OrganisationType).map((type) => {
                                    return (
                                        type !== OrganisationType.None &&
                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={submitDisabled}
                        color="success" type="submit"
                        variant="outlined"
                        sx={{
                            width: "fit-content",
                            alignSelf: "center"
                        }}>
                        {create ? "Create" : "Update"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default EditSiteForm;