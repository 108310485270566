import { Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from "@mui/material";

interface ConfirmationModalProps {
    open: boolean;
    accept: () => void;
    onClose: () => void;
    title: string;
    messages: string[];
    warningLine?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    open,
    accept,
    onClose,
    title,
    messages,
    warningLine,
}) => {

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
                <Stack direction="column" spacing={2} p={2}>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    {messages.map((message, index) => (
                        <Typography key={index} variant="body1">
                            {message}
                        </Typography>
                    ))}
                    {warningLine &&
                        <Typography variant="h5">
                            <b>{warningLine}</b>
                        </Typography>}
                </Stack>
                <DialogActions>
                    <Button onClick={accept} variant="outlined" color="info">Yes</Button>
                    <Button onClick={onClose} variant="outlined" color="error">No</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default ConfirmationModal;