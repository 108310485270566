import * as React from "react";

import { Stack, TextField } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

import { useFormik } from "formik";
import * as Yup from "yup";

import TelehealthIconButton from "../telehealth-icon-button";

interface SingleTextFieldFormProps {
  fieldName: string;
  label: string;
  defaultValue: string;
  minLength?: number;
  maxLength?: number;
  onSubmit: (value: string) => void;
}

const SingleTextFieldForm: React.FC<SingleTextFieldFormProps> = ({
  fieldName,
  label,
  defaultValue,
  minLength = 0,
  maxLength = 24,
  onSubmit,
}) => {

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      [fieldName]: defaultValue || ""
    },
    //require length above 0
    validationSchema: Yup.object({
      [fieldName]: Yup.string()
        .required(`${label} is required"`)
        .min(minLength, `${label} is too short`)
        .max(maxLength, `${label} is too long`),
    }),
    onSubmit: (values) => {
      const { [fieldName]: name } = values;

      if (!name) {
        return;
      }

      onSubmit(name);
    },
  });

  const fieldNameChanged = defaultValue !== formik.values[fieldName];

  const onSubmitButton = () => {
    formik.handleSubmit();
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        direction={"row"}
        spacing={0.5}
        alignContent={"center"}
        justifyContent={"center"}>
        <Stack paddingY={2} >
          <TextField
            fullWidth
            id={fieldName}
            name={fieldName}
            label={label}
            variant="standard"
            autoComplete={fieldName}
            value={formik.values[fieldName]}
            onChange={formik.handleChange}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            sx={{
              minWidth: "180px",
            }}
          />
        </Stack>
        <Stack width={"100%"} justifyContent={"end"} paddingY={0.5}>
          <TelehealthIconButton
            onClick={onSubmitButton}
            disabled={!formik.isValid || !fieldNameChanged}
            color="primary"
            icon={<SaveIcon />}
            tooltip={fieldNameChanged ? "Save changes" : "No changes to save"}
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default SingleTextFieldForm;
