export enum MeetingStatus {
  Scheduled = "Scheduled",
  Confirmed = "Confirmed",
  Cancelled = "Cancelled",
  Training = "Training",
}

export enum MeetingJoinType {
  Metro = "Metro",
  Mobile = "Mobile",
  Headset = "Headset",
  Phone = "Phone",
  Remote = "Remote",
  Training = "Training",
}

export enum OrganisationType {
  None = "None",
  Remote = "Remote",
  Metro = "Metro",
}

export enum UserType {
  Inactive = "Inactive",
  Temporary = "Temporary",
  User = "User",
  OrgAdmin = "Organisation Admin",
  SysAdmin = "System Admin",
}

export enum ResourceType {
  Users = "users",
  Organisation = "organisation",
  Patients = "patients",
  Meetings = "meetings",
  Uploads = "uploads",
  Chime = "chime",
  Site = "site",
}

export const UserTypeMapping: { [key in UserType]: { value: string; number: number } } = {
  [UserType.Inactive]: { value: UserType.Inactive, number: 0 },
  [UserType.Temporary]: { value: UserType.Temporary, number: 1 },
  [UserType.User]: { value: UserType.User, number: 2 },
  [UserType.OrgAdmin]: { value: UserType.OrgAdmin, number: 3 },
  [UserType.SysAdmin]: { value: UserType.SysAdmin, number: 4 },
};

export enum MessageEventType {
  Created = "created",
  Updated = "updated",
  Deleted = "deleted",
}

export interface User {
  userId: string;
  fullName: string;
  jobTitle: string;
  organisationId: string;
  userType: UserType;
  permissionSet: boolean;
  email?: string;
  lastLogin?: Date;
}

export interface Organisation {
  organisationId: string;
  name: string;
  type: OrganisationType;
  color: string;
  usersIds: string[];
  siteIds: string[];
}

export interface Patient {
  patientId: string;
  created: Date;
  meetingIds: string[];
}

export interface Meeting {
  meetingId: string;
  creatorId: string;
  meetingName: string;
  chimeJoinId: string;
  realStartTime?: Date;
  startTime: Date;
  endTime: Date;
  attendeesId: string[];
  confirmedAttendeesId: string[];
  joinedIds: string[];
  organisationIds: string[];
  patientId: string;
  siteIds: string[];
  meetingType: MeetingStatus;
}

export interface MeetingJoinInfo {
  meetingId: string;
  joinedIds: string[];
  attendeesId: string[];
  confirmedAttendeesId: string[];
  startTime?: Date;
}

export interface Site {
  siteId: string;
  siteName: string;
  color: string;
  linkedOrganisations: string[];
  siteType: OrganisationType;
}

export interface IotMessage {
  messageId: string;
  resourceType: ResourceType;
  sender?: string;
  eventType: MessageEventType;
  resourceIds: string[];
  customInfo?: string;
}

export interface NotificationMessage extends IotMessage {
  expiry: Date;
}

export const validId = (toCheck: string | null | undefined) => {
  // /^[a-zA-Z0-9] with underscore and dash allowed
  return validString(toCheck, /^[a-zA-Z0-9_-]+$/, 0, 36);
}

export const validIdList = (toCheck: string[] | null | undefined) => {
  if (toCheck == null) {
    console.log("missing parameter");
    return {
      valid: false,
      message: "Missing parameter"
    }
  }

  // check if array
  if (!Array.isArray(toCheck)) {
    console.log("parameter is not an array");
    return {
      valid: false,
      message: "Parameter is not an array"
    }
  }

  const uniqueIds: string[] = []
  for (let i = 0; i < toCheck.length; i++) {
    if (!validId(toCheck[i])) {
      console.log("invalid id: " + toCheck[i]);
      return {
        valid: false,
        message: `Invalid id: ${toCheck[i]}`
      }
    }

    if (uniqueIds.includes(toCheck[i])) {
      console.log("duplicate id: " + toCheck[i]);
      return {
        valid: false,
        message: `Duplicate id: ${toCheck[i]}`
      }
    }

    uniqueIds.push(toCheck[i]);
  }

  return {
    valid: true,
    message: "valid id list"
  }
}

export const validField = (toCheck: string | null | undefined, min?: number, max?: number) => {

  if (!min) {
    min = 0
  }

  if (!max) {
    max = 24
  }

  return validString(toCheck, /^[a-zA-Z0-9:_ -]+$/, min, max);
}

export const validColor = (toCheck: string | null | undefined) => {
  return validString(toCheck, /^#[0-9A-Fa-f]{6}$/, 7, 7);
}

const validString = (toCheck: string | null | undefined, regex: RegExp, min?: number, max?: number) => {

  if (toCheck == undefined) {
    console.log("missing parameter");
    return false;
  }

  if (toCheck == null) {
    console.log("missing parameter");
    return false;
  }

  if (min && toCheck.length < min) {
    console.log(`string is too short: ${toCheck.length} < ${min}`);
    return false;
  }

  if (max && toCheck.length > max) {
    console.log(`string is too long: ${toCheck.length} > ${max}`);
    return false;
  }

  return regex.test(toCheck);
}

export const canJoin = (meeting?: Meeting, disableJoinOffset?: boolean) => {

  if (!meeting) {
    return false
  }

  if (meeting.meetingType === MeetingStatus.Cancelled) {
    return false
  }

  if (meeting.startTime == null || meeting.endTime == null) {
    return false
  }

  const now = new Date()

  // can join 15 minutes before event starts
  const startJoinOffset = disableJoinOffset ? 0 : 900000;

  // can join four hours after event ends
  const endJoinOffset = disableJoinOffset ? 0 : 14400000;

  if (now.getTime() + startJoinOffset < meeting.startTime.getTime()) {
    return false
  }

  return now.getTime() - endJoinOffset < meeting.endTime.getTime()
}

export const getEventStatus = (meeting: Meeting, joined: string[], realStart?: Date) => {

  if (!meeting) {
    return `Invalid meeting.`
  }

  if (meeting.meetingType === MeetingStatus.Cancelled) {
    return `Meeting has been cancelled.`
  }

  if (canJoin(meeting)) {

    if (realStart) {
      return GetTimeSinceMeetingJoined(realStart)
    }

    if (joined.length > 0) {
      return `Can join meeting.`
    }
  }

  const now = new Date()

  if (now < meeting.startTime) {
    return `Meeting has not started.`
  }

  if (now > meeting.endTime) {
    return `Meeting has ended.`
  }

  return `Can join meeting.`
}

const GetTimeSinceMeetingJoined = (startTime: Date) => {
  const currentTime = new Date()

  if (startTime > currentTime) {
    return "Join meeting:"
  }

  const timeSince = currentTime.getTime() - new Date(startTime).getTime()
  const minutesSince = Math.floor(timeSince / 60000)
  return `Meeting started ${minutesSince} minutes ago.`
}

export const canUserEditMeetings = (user: User | undefined, organisation: Organisation | undefined | null) => {
  if (!user) {
    return {
      canEdit: false,
      reason: "User does not exist"
    }
  }

  if (!organisation) {
    return {
      canEdit: false,
      reason: "Organisation does not exist"
    }
  }

  if (organisation.organisationId != user.organisationId) {
    throw new Error("User does not belong to provided organisation");
  }

  if (organisation.type != OrganisationType.Metro) {
    return {
      canEdit: false,
      reason: "Organisation is not a metro organisation"
    }
  }

  return {
    canEdit: true,
    reason: "User can edit meetings"
  }
}

export const canMeetingBeEdited = (meeting: Meeting | undefined, userId: string, organisationId: string) => {
  if (!meeting) {
    return {
      canEdit: false,
      reason: "Meeting does not exist"
    }
  }

  if (meeting.chimeJoinId.length > 0) {
    return {
      canEdit: false,
      reason: "Meeting has already started"
    }
  }

  if (canJoin(meeting, true)) {
    return {
      canEdit: false,
      reason: "Meeting has already started"
    }
  }

  if (meeting.startTime < new Date()) {
    return {
      canEdit: false,
      reason: "Meeting has already ended"
    }
  }

  if (meeting.meetingType !== MeetingStatus.Scheduled) {

    const message =
      meeting.meetingType === MeetingStatus.Confirmed ?
        "Meeting has already started"
        : "Meeting has been cancelled";

    return {
      canEdit: false,
      reason: message
    }
  }

  if (meeting.attendeesId.includes(userId)) {
    return {
      canEdit: true,
      reason: "User is an attendee"
    }
  }

  if (meeting.organisationIds.includes(organisationId)) {
    return {
      canEdit: true,
      reason: "User is an organisation member"
    }
  }

  return {
    canEdit: false,
    reason: "User does not have permission to edit meeting"
  }
}

export const userHasPermission = (requiredLevel: UserType, user: User | null | undefined) => {

  if (!user) {
    return false;
  }

  const userTypeValue = UserTypeMapping[user.userType].number;
  const requiredTypeValue = UserTypeMapping[requiredLevel].number;

  return userTypeValue >= requiredTypeValue;
}

export const canUserEditUser = (user: User | null | undefined, targetUser: User | null | undefined) => {
  if (!user) {
    console.log("User is null");
    return false;
  }

  if (!targetUser) {
    console.log("Target user is null");
    return false;
  }

  if (user.userType === UserType.SysAdmin) {
    return true;
  }

  if (targetUser.userType === UserType.SysAdmin || targetUser.userType === UserType.OrgAdmin) {
    return false;
  }

  if (user.userType === UserType.OrgAdmin) {
    return user.organisationId === targetUser.organisationId;
  }

  return false;
}