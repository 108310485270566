import React, { PropsWithChildren, useEffect } from "react";

import { Stack } from "@mui/material";

import { ConnectIoTContext } from "../websocket/connect-iot";
import { MeetingInfoContext } from "../context/meeting-info-context";
import { useGetOrganisation, useGetUser } from "../store";

import AnnotationGrid from "./annotation-grid";
import AnnotationControlBar from "./annotation-control-bar";

export enum MessageType {
    Annotation = "annotation",
    Clear = "clear",
    Undo = "undo",
    RequestPoints = "request-points",
    SendPoints = "send-points",
    Color = "color",
    Active = "active",
    MouseUpdate = "mouse-update",
}

export interface Point {
    x: number;
    y: number;
}

export interface Drawing {
    points: Point[][];
    color: string;
    userMouse: Point | null;
}

interface SendAnnotationMessageProps {
    userId: string;
}

const SendAnnotationMessage: React.FC<SendAnnotationMessageProps> = ({
    userId,
}) => {

    const { subscribeToChannel, unsubscribeFromChannel, sendMessage, iotConnected } = React.useContext(ConnectIoTContext);

    const { connected, meetingId, canAnnotate, setCanAnnotate, showAnnotation, setShowAnnotation, annotationUrlIndex, setAnnotationUrlIndex } = React.useContext(MeetingInfoContext);

    const [pState, setPState] = React.useState<{ [id: string]: Drawing }>({});
    const [lineIndex, setLineIndex] = React.useState<number>(-1);

    const [requestPoints, setRequestPoints] = React.useState<string[]>([]);

    const { data: currentUser } = useGetUser(userId);
    const { data: organisation } = useGetOrganisation(currentUser?.organisationId || "");

    const channelName = `liveMeeting/${meetingId}/annotation`;

    let canReset = false;

    let color = "#e19b9b";
    if (pState[userId]) {
        canReset = pState[userId].points.length > 0 && pState[userId].points[0].length > 0;
        color = pState[userId].color;
    }
    else if (organisation != null) {
        color = organisation.color;
    }

    const setColor = (color: string, targetId: string) => {

        setPState(prevPState => {

            const updated = { ...prevPState };

            if (!updated[targetId]) {
                updated[targetId] = { points: [], color, userMouse: null };
            } else {
                updated[targetId].color = color;
            }

            return updated;
        });
    }

    const respondToMessage = (topicName: string, payload: string) => {

        if (topicName !== channelName) {
            console.warn("~~Received message from unexpected channel", topicName);
            return;
        }

        const { type, sender, target } = JSON.parse(payload);

        switch (type) {
            case MessageType.Annotation: {
                receiveAnnotationMessage(payload, sender);
                break
            }
            case MessageType.Clear: {
                receiveClearMessage(sender);
                break
            }
            case MessageType.Undo: {
                receiveUndoMessage(sender);
                break
            }
            case MessageType.RequestPoints: {
                receiveRequestPointsMessage(payload, sender);
                break
            }
            case MessageType.SendPoints: {
                receiveSendPointsMessage(target, sender, payload);
                break
            }
            case MessageType.Color: {
                receiveColorMessage(sender, payload);
                break;
            }
            case MessageType.Active: {
                receiveActiveMessage(payload);
                break;
            }
            case MessageType.MouseUpdate: {
                receiveMouseUpdateMessage(payload, sender);
                break;
            }
            default: {
                console.warn("~~Received unexpected message type", type);
            }
        }
    }

    const receiveActiveMessage = (payload: string) => {

        const { active, url } = JSON.parse(payload);

        setActiveState(active, url);
    }

    const setActiveState = (active: any, url: any) => {

        const isActive = active === true || active === "true";

        console.log("~~Received active message ^&^&", isActive, url);

        if (isActive) {
            if (url === annotationUrlIndex) {
                console.log("~~Received active message with same url", url);
                return;
            }

            if (url === null || url === undefined) {
                console.warn("~~Received active message with no url");
                return;
            }
        }

        if (!isActive) {
            setAnnotationUrlIndex(null);
            setShowAnnotation(isActive);
            setPState({});
            return;
        }

        console.log("~~ ^^ Received active message", isActive, url);

        setAnnotationUrlIndex(url);
        setShowAnnotation(isActive);
    }

    const receiveColorMessage = (sender: string, payload: string) => {

        const { color } = JSON.parse(payload);

        setColor(color, sender);
    }

    const receiveAnnotationMessage = (payload: string, sender: string) => {

        const { x, y, color, lineIndex } = JSON.parse(payload);

        setPState(prevPState => {
            const updated = { ...prevPState };

            if (!updated[sender]) {
                updated[sender] = { points: [[{ x, y }]], color, userMouse: null };
            }

            for (let i = updated[sender].points.length - 1; i < lineIndex; i++) {
                updated[sender].points.push([]);
            }

            updated[sender].points[lineIndex].push({ x, y });

            return updated;
        });
    }

    const receiveClearMessage = (sender: string) => {
        setPState(prevState => {
            const newState = { ...prevState };
            newState[sender].points = [];
            return newState;
        });

        setLineIndex(-1);
    }

    const receiveUndoMessage = (sender: string) => {
        setPState(prevState => {
            const newState = { ...prevState };
            newState[sender].points.pop();
            return newState;
        });

        if (sender === userId) {
            setLineIndex(prevIndex => prevIndex - 1);
        }
    }

    const receiveRequestPointsMessage = (payload: string, sender: string) => {

        if (sender === userId) {
            console.log("~~Received request points message from self");
            return;
        }

        const { color: otherUserColor } = JSON.parse(payload);

        setRequestPoints(prevRequestPoints => {
            if (prevRequestPoints.includes(sender)) {
                return prevRequestPoints;
            } else {
                return [...prevRequestPoints, sender];
            }
        });

        setColor(otherUserColor, sender);

        // send color back to requester
        const message = {
            sender: userId,
            type: MessageType.Color,
            color,
        }

        sendMessage(channelName, JSON.stringify(message));

        console.log("~~Received request points message from", sender, otherUserColor);
    }

    const receiveSendPointsMessage = (target: string, sender: string, payload: string) => {

        if (target != userId) {
            console.log("~~Received send points message for another user", target);
            return;
        }

        const { points, url, active } = JSON.parse(payload);

        console.log("~~Received points", points);

        setPState(prevState => {
            const updated = { ...prevState };

            updated[sender] = points;

            return updated;
        });

        setActiveState(active, url);

        console.log("~~Received send points message to", target);
    }

    const receiveMouseUpdateMessage = (payload: string, sender: string) => {

        if (sender === userId) {
            return;
        }

        const { x, y } = JSON.parse(payload);

        setPState(prevPState => {
            const updated = { ...prevPState };

            if (!updated[sender]) {
                console.log("~~No user state found for", sender);
                updated[sender] = { points: [], color, userMouse: { x, y } };
            } else {
                updated[sender].userMouse = { x, y };
            }

            return updated;
        });
    }

    useEffect(() => {

        const onFinished = (error?: string) => {

            if (error) {
                console.error("~~Error subscribing to annotation channel", error);
                return;
            }

            let startColor = "#e19b9b";
            if (organisation) {
                startColor = organisation.color;
            }

            const message = {
                sender: userId,
                type: MessageType.RequestPoints,
                color: startColor,
            }

            sendMessage(channelName, JSON.stringify(message));

            setColor(startColor, userId);
        }

        const waitForConnection = async () => {

            console.log("~~Starting subscription", channelName);

            const response = await subscribeToChannel(channelName, respondToMessage);

            console.log("~~Got subscription response", channelName);

            if (response.statusCode !== 200) {
                console.error("~~Error subscribing to annotation channel", response.message);
                return;
            }

            setCanAnnotate(true);

            onFinished();
        }

        if (!connected) {
            return;
        }

        if (!iotConnected) {
            return;
        }

        if (canAnnotate) {
            return;
        }

        waitForConnection();

        // Clean up
        return () => {
            console.log("Unsubscribing from annotation channel");
            // unsubscribeFromChannel(channelName);
            // setListenerSet(false);
        }

    }, [connected, iotConnected]);

    useEffect(() => {
        if (!connected) {
            return;
        }

        if (requestPoints.length === 0) {
            return;
        }

        requestPoints.forEach(target => {

            const message = {
                sender: userId,
                type: MessageType.SendPoints,
                points: pState[userId],
                target,
                active: showAnnotation,
                url: annotationUrlIndex,
            }

            sendMessage(channelName, JSON.stringify(message));
        });

        console.log("~~Send points message sent to", requestPoints);

        setRequestPoints([]);
    }, [requestPoints]);



    const sendPointAddedMessage = (x: number, y: number, newLine: boolean) => {

        if (!canAnnotate) {
            console.error("~~Not connected, cannot annotate");
            return;
        }

        let index = lineIndex;

        if (newLine || index === -1) {
            index++;
            setLineIndex(index);
        }

        const message = {
            sender: userId,
            type: MessageType.Annotation,
            x,
            y,
            color,
            lineIndex: index,
        }

        sendMessage(channelName, JSON.stringify(message));
        console.log("~~Annotation message sent", message);
    }

    const handleColorChange = (color: string) => {

        if (!canAnnotate) {
            console.error("~~Not connected, cannot annotate");
            return;
        }

        setColor(color, userId);

        const message = {
            sender: userId,
            type: MessageType.Color,
            color,
        }

        sendMessage(channelName, JSON.stringify(message));
    }

    const handleClearOnClick = () => {

        if (!canAnnotate) {
            console.error("~~Not connected, cannot annotate");
            return;
        }

        const message = {
            sender: userId,
            type: MessageType.Clear,
        }

        sendMessage(channelName, JSON.stringify(message));
        console.log("~~Clear message sent", message);
    }

    const handleUndoOnClick = () => {
        if (!canAnnotate) {
            console.error("~~Not connected, cannot annotate");
            return;
        }

        const message = {
            sender: userId,
            type: MessageType.Undo,
        }

        sendMessage(channelName, JSON.stringify(message));
        console.log("~~Undo message sent");
    }

    const onMouseMove = (x: number, y: number) => {
        if (!canAnnotate) {
            return;
        }

        setPState(prevPState => {
            const updated = { ...prevPState };

            if (!updated[userId]) {
                console.log("~~No user state found for", userId);
                updated[userId] = { points: [], color, userMouse: { x, y } };
            } else {
                updated[userId].userMouse = { x, y };
            }

            return updated;
        });

        const message = {
            sender: userId,
            type: MessageType.MouseUpdate,
            x,
            y,
        }

        sendMessage(channelName, JSON.stringify(message));
    }

    return (
        <Stack spacing={1}>
            <AnnotationGrid
                userId={userId}
                points={pState}
                onClick={sendPointAddedMessage}
                onMouseMove={onMouseMove} />
            {showAnnotation &&
                <div style={{
                    position: 'fixed',
                    left: "10px",
                    top: "40%",
                    zIndex: 1000,
                }}>
                    <AnnotationControlBar
                        color={color}
                        handleColorChange={handleColorChange}
                        onReset={handleClearOnClick}
                        onUndo={handleUndoOnClick}
                        canReset={canReset}
                    />
                </div>}
        </Stack>
    )
}

export default SendAnnotationMessage;