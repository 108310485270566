import React, { useContext, useEffect } from "react";

import { useMeetingManager } from "amazon-chime-sdk-component-library-react";

import { useGetPhotoUrls } from "../../store";
import { MeetingInfoContext } from "../../context/meeting-info-context";

import toast from "react-hot-toast";
import DownloadImageModal from "./download-image-modal";
import ViewModelModal from "../three/view-model-modal";
import { Stack } from "@mui/material";

interface DownloadImageProps {
    showText?: boolean;
}

const DownloadPhotoUrlModal: React.FC<DownloadImageProps> = ({
    showText = true
}) => {

    const [lastModelCount, setLastModelCount] = React.useState(-1);
    const [lastPhotoCount, setLastPhotoCount] = React.useState(-1);

    const meetingManager = useMeetingManager();

    const { userId, patientId } = useContext(MeetingInfoContext);

    const meetingId = meetingManager.meetingSessionConfiguration?.externalMeetingId || "";

    const { data: urlSet } = useGetPhotoUrls(userId, meetingId);

    useEffect(() => {
        if (!urlSet) {
            return;
        }

        if (lastModelCount === -1 && lastPhotoCount === -1) {
            setLastModelCount(urlSet.photoUrls.length);
            setLastPhotoCount(urlSet.modelUrls.length);
            return;
        }

        const newModelAdded = urlSet.modelUrls.length > lastModelCount;
        const newPhotoAdded = urlSet.photoUrls.length > lastPhotoCount;

        if (!newModelAdded && !newPhotoAdded) {
            return;
        }

        if (newPhotoAdded) {
            toast.success("New image available for download", { duration: 3000 })
            console.log("~~~", "New image available for download")
        }

        if (newModelAdded) {
            toast.success("New model available", { duration: 3000 })
            console.log("~~~", "New model available")
        }

        setLastModelCount(urlSet.modelUrls.length);
        setLastPhotoCount(urlSet.photoUrls.length);

    }, [urlSet, meetingManager.meetingSessionConfiguration?.externalMeetingId]);

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyItems={"center"}
            spacing={2}
        >
            <DownloadImageModal
                showText={showText}
                downloadURLs={urlSet?.photoUrls || []} />
            <ViewModelModal
                showText={showText}
                imageSources={urlSet?.modelUrls || []}
                patientId={patientId} />
        </Stack>
    )
}

export default DownloadPhotoUrlModal;