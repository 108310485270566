import React from "react";

import { Stack, TableCell, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SendIcon from '@mui/icons-material/Send';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { canUserEditUser, User, UserType } from "../../types";
import { useGetCurrentUser, useUpdateUser } from "../../store";
import TelehealthIconButton from "../telehealth-icon-button";
import SingleTextFieldForm from "../login/single-text-field-form";
import toast from "react-hot-toast";

interface TemporaryUserTableRowProps {
    deleteUser: (user: User) => void;
    convertTemporaryUser: (user: User) => void;
    transferUser: (user: User) => void;
    user: User;
}

const TemporaryUserTableRow: React.FC<TemporaryUserTableRowProps> = ({
    deleteUser,
    convertTemporaryUser,
    transferUser,
    user,
}) => {

    const onSuccess = () => {
        toast.success("Details updated successfully", { duration: 5000 });
    }

    const onError = (error: any) => {
        console.log(error);
        toast.error("Failed to update details", { duration: 5000 });
    }

    const { data: currentUser } = useGetCurrentUser();
    const { mutate: updateUser } = useUpdateUser(onSuccess, onError);

    const isAdmin = currentUser?.userType === UserType.SysAdmin;

    const canEdit = canUserEditUser(currentUser, user)

    const canDelete = canEdit && currentUser?.userId !== user.userId

    const updateFullName = (fullName: string) => {
        const updatedUser: User = {
            ...user,
            fullName,
        }
        updateUser(updatedUser);
    }

    const updateJobTitle = (jobTitle: string) => {
        const updatedUser: User = {
            ...user,
            jobTitle,
        }
        updateUser(updatedUser);
    }

    let jobTitle = user?.jobTitle || "";
    jobTitle = jobTitle.length > 0 ? jobTitle : "Not Set";

    return (
        <>
            <TableCell align="left">
                <SingleTextFieldForm
                    fieldName="fullName"
                    label="Full Name"
                    defaultValue={user?.fullName || ""}
                    minLength={2}
                    maxLength={24}
                    onSubmit={updateFullName} />
            </TableCell>
            <TableCell align="left">
                {user &&
                    <SingleTextFieldForm
                        fieldName="jobTitle"
                        label="Job Title"
                        defaultValue={jobTitle}
                        maxLength={24}
                        onSubmit={updateJobTitle} />}
                {!user && "???"}
            </TableCell>
            <TableCell align="left">
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent={"left"}>
                    <TelehealthIconButton
                        onClick={() => convertTemporaryUser(user)}
                        tooltip="Invite User to join"
                        disabled={!canEdit}
                        color="info"
                        icon={<SendIcon />}
                    />
                </Stack>
            </TableCell >
            <TableCell align="right">
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent={"right"}>
                    {isAdmin &&
                        <TelehealthIconButton
                            icon={<SwapHorizIcon />}
                            color={"primary"}
                            tooltip="Swap User organisation"
                            inactiveTooltip="You do not have permission to swap user organisation"
                            disabled={!canEdit}
                            onClick={() => transferUser(user)} />}
                    <TelehealthIconButton
                        icon={<DeleteForeverIcon />}
                        color="error"
                        tooltip="Delete User"
                        inactiveTooltip="You do not have permission to delete this user"
                        disabled={!canDelete}
                        onClick={() => deleteUser(user)} />
                </Stack>
            </TableCell >
        </>
    )
}

export default TemporaryUserTableRow;