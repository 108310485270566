import React from "react";

import DrawIcon from '@mui/icons-material/Draw';
import HideImageIcon from '@mui/icons-material/HideImage';

import { MeetingInfoContext } from "../../context/meeting-info-context";
import { ConnectIoTContext } from "../../websocket/connect-iot";
import { MessageType } from "../../annotation/send-annotation-message";

import ControlButton from "../control-button";

interface ActivateLiveAnnotationProps {
    expired?: boolean;
    urlIndex?: number;
    showText?: boolean;
    liveAnnotationChanged?: (showAnnotation: boolean) => void;
}

const ActivateLiveAnnotation: React.FC<ActivateLiveAnnotationProps> = ({
    expired = false,
    urlIndex,
    showText,
    liveAnnotationChanged,
}) => {

    const { connected, meetingId, canAnnotate } = React.useContext(MeetingInfoContext);

    const channelName = `liveMeeting/${meetingId}/annotation`;

    const { sendMessage } = React.useContext(ConnectIoTContext);

    const { setAnnotationUrlIndex, showAnnotation, setShowAnnotation } = React.useContext(MeetingInfoContext);

    const onClick = () => {

        if (showAnnotation) {
            updateUrl(0, false);
            return;
        }

        if (urlIndex === null || urlIndex === undefined) {
            return;
        }

        updateUrl(urlIndex, true);
    }

    const updateUrl = (url: number, active: boolean) => {
        setShowAnnotation(active);
        setAnnotationUrlIndex(url);

        const message = {
            type: MessageType.Active,
            active,
            url: urlIndex
        }

        console.log("~~Sending message", message);

        sendMessage(channelName, JSON.stringify(message));

        if (liveAnnotationChanged) {
            liveAnnotationChanged(active);
        }
    }

    const getTooltip = () => {
        if (!connected) {
            return "Live annotation disabled. Not connected to annotation server";
        }

        if (expired) {
            return "Live annotation disabled. Annotation URL has expired";
        }

        if (urlIndex === null) {
            return "Live annotation disabled. No image selected for annotation";
        }

        if (!canAnnotate) {
            return "Live annotation disabled. Not authorized to annotate";
        }

        if (showAnnotation) {
            return "Stop Live Annotation";
        }

        return "Start Live Annotation";
    }

    const disabled = !connected || (urlIndex === null && !showAnnotation) || expired || !canAnnotate;

    const tooltipText = getTooltip();

    return (
        <ControlButton
            showText={showText}
            disabled={disabled}
            onClick={onClick}
            active={showAnnotation}
            activeColor={"#c62828"}
            activeIcon={<HideImageIcon />}
            inactiveIcon={<DrawIcon />}
            activeLabel={tooltipText}
            inactiveLabel={tooltipText}
        />
    )
}

export default ActivateLiveAnnotation;