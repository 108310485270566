import DrawingLine from "./drawing-line"
import { Drawing, Point } from "./send-annotation-message"


interface DrawingLineGroupProps {
    keyProp: string
    drawing: Drawing
    userId: string
    width: number
    height: number
    showMouse: boolean
    scale: number;
    translateX: number;
    translateY: number;
    isDragging: boolean;
}

const DrawingLineGroup: React.FC<DrawingLineGroupProps> = ({
    drawing,
    userId,
    width,
    height,
    keyProp,
    showMouse,
    scale,
    translateX,
    translateY,
    isDragging,
}) => {

    const pointList = drawing.points.length > 0 ? drawing.points : [[]];

    return (
        <>
            {pointList.map((line, index) => {
                return (
                    <DrawingLine
                        key={`drawingLine${keyProp}${index}`}
                        drawing={drawing}
                        userId={userId}
                        width={width}
                        height={height}
                        showMouse={showMouse && index === 0}
                        lineIndex={index}
                        keyProp={keyProp}
                        scale={scale}
                        translateX={translateX}
                        translateY={translateY}
                        isDragging={isDragging}
                    />
                )
            })}
        </>
    )
}

export default DrawingLineGroup