import React from "react";

import { Stack, TableCell, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SendIcon from '@mui/icons-material/Send';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { canUserEditUser, User, UserType } from "../../types";
import { useGetCurrentUser, useUpdateUser } from "../../store";
import TelehealthIconButton from "../telehealth-icon-button";
import UpdateUserType from "./update-user-type";
import CustomTag from "../custom-tag";
import SingleTextFieldForm from "../login/single-text-field-form";
import toast from "react-hot-toast";

interface UserTableRowProps {
    deleteUser: (user: User) => void;
    convertTemporaryUser: (user: User) => void;
    transferUser: (user: User) => void;
    user: User;
}

const UserTableRow: React.FC<UserTableRowProps> = ({
    deleteUser,
    convertTemporaryUser,
    transferUser,
    user,
}) => {

    const onSuccess = () => {
        toast.success("Details updated successfully", { duration: 5000 });
    }

    const onError = (error: any) => {
        console.log(error);
        toast.error("Failed to update details", { duration: 5000 });
    }

    const { data: currentUser } = useGetCurrentUser();
    const { mutate: updateUser } = useUpdateUser(onSuccess, onError);

    const isAdmin = currentUser?.userType === UserType.SysAdmin;

    const hasEmail = Boolean(user?.email && user?.email.length > 0)

    const isTemporaryUser = user?.userType === UserType.Temporary && !hasEmail

    const canEdit = canUserEditUser(currentUser, user)

    const canDelete = canEdit && currentUser?.userId !== user.userId

    const checkCanChangeType = (isAdmin: boolean, user?: User) => {
        if (!isAdmin) {
            return false;
        }

        if (!user) {
            return false;
        }

        return user.userType !== UserType.Temporary;
    }

    const changeChangeType = checkCanChangeType(isAdmin, user);

    const formattedDateTimeString = (date?: Date) => {
        if (!date) {
            return undefined;
        }

        return date.toLocaleDateString("en-GB", { weekday: "long", month: "long", day: "numeric", hour: "numeric", minute: "numeric" })
    }

    const lastLogin = formattedDateTimeString(user.lastLogin);

    const updateFullName = (fullName: string) => {
        const updatedUser: User = {
            ...user,
            fullName,
        }
        updateUser(updatedUser);
    }

    const updateJobTitle = (jobTitle: string) => {
        const updatedUser: User = {
            ...user,
            jobTitle,
        }
        updateUser(updatedUser);
    }

    let jobTitle = user?.jobTitle || "";
    jobTitle = jobTitle.length > 0 ? jobTitle : "Not Set";

    return (
        <>
            <TableCell align="left">
                {!isTemporaryUser &&
                    <CustomTag
                        text={user.fullName || ""}
                        color="#e6e6e6"
                        sx={{
                            height: "fit-content",
                            width: "fit-content",
                        }} />}
                {isTemporaryUser &&
                    <SingleTextFieldForm
                        fieldName="fullName"
                        label="Full Name"
                        defaultValue={user?.fullName || ""}
                        minLength={2}
                        maxLength={24}
                        onSubmit={updateFullName} />}
            </TableCell>
            <TableCell align="left">
                {!isTemporaryUser &&
                    <Typography variant="body1" fontSize={16}>
                        {jobTitle}
                    </Typography>}
                {isTemporaryUser && user &&
                    <SingleTextFieldForm
                        fieldName="jobTitle"
                        label="Job Title"
                        defaultValue={jobTitle}
                        maxLength={24}
                        onSubmit={updateJobTitle} />}
            </TableCell>
            <TableCell align="left">
                <Stack
                    direction={"row"}
                    width={"100%"}
                    spacing={2}
                    justifyContent={isTemporaryUser ?
                        "space-evenly" : "left"}
                    alignItems={"center"}>
                    <Typography variant="body1" fontSize={16}>
                        {user?.email || "No email"}
                    </Typography>
                    {isTemporaryUser &&
                        <TelehealthIconButton
                            onClick={() => convertTemporaryUser(user)}
                            tooltip="Invite User to join"
                            disabled={!canEdit}
                            color="info"
                            icon={<SendIcon />}
                        />}
                </Stack>
            </TableCell >
            <TableCell align="left">
                <Typography variant="body1" fontSize={16}>
                    {lastLogin ? lastLogin : "Never"}
                </Typography>
            </TableCell>
            <TableCell align="left">
                {changeChangeType &&
                    <Stack paddingY={2} direction="row">
                        <UpdateUserType user={user} />
                    </Stack>}
                {!changeChangeType &&
                    <Typography variant="body1" fontSize={16}>
                        {user.userType}
                    </Typography>}
            </TableCell>
            <TableCell align="right">
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent={"right"}>
                    {isAdmin &&
                        <TelehealthIconButton
                            icon={<SwapHorizIcon />}
                            color={"primary"}
                            tooltip="Swap User organisation"
                            inactiveTooltip="You do not have permission to swap user organisation"
                            disabled={!canEdit}
                            onClick={() => transferUser(user)} />}
                    <TelehealthIconButton
                        icon={<DeleteForeverIcon />}
                        color="error"
                        tooltip="Delete User"
                        inactiveTooltip="You do not have permission to delete this user"
                        disabled={!canDelete}
                        onClick={() => deleteUser(user)} />
                </Stack>
            </TableCell>
        </>
    )
}

export default UserTableRow;