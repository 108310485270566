import React from "react";
import { Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

import { Site } from "../../types";

import CancelButton from "../cancel-button";
import AddSiteForm from "./add-site-form";

interface EditSiteModalProps {
    open: boolean;
    onClose: () => void;
    updateSite: (Site: Site) => void;
    site: Site;
    create: boolean;
}

const EditSiteModal: React.FC<EditSiteModalProps> = ({
    open,
    onClose,
    site: Site,
    updateSite,
    create,
}) => {

    const closeModal = () => {
        onClose();
    }

    const onUpdate = (updated: Site) => {
        updateSite(updated);
        closeModal();
    }

    // form component is required for formik to work
    return (
        <Dialog open={open} onClose={closeModal} maxWidth={"md"}>
            <Stack spacing={2}>
                <DialogTitle component={"div"}>
                    <Typography variant="h3">
                        {create ? "Add Site" : "Edit Site"}
                    </Typography>
                    <CancelButton handleClose={closeModal} />
                </DialogTitle>
                <DialogContent>
                    <AddSiteForm
                        updateSite={onUpdate}
                        site={Site}
                        create={create} />
                </DialogContent>
            </Stack>
        </Dialog >
    )
}

export default EditSiteModal;