import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { OrganisationType } from "../../types";

interface OrganisationTypeSelectProps {
    selectedType: OrganisationType;
    updateType: (event: SelectChangeEvent<string>) => void;
}

const OrganisationTypeSelect: React.FC<OrganisationTypeSelectProps> = ({
    selectedType,
    updateType,
}) => {

    return (
        <FormControl fullWidth>
            <InputLabel id="type-select-label" color="primary">
                Type
            </InputLabel>
            <Select
                name="type"
                label="Type"
                value={selectedType}
                onChange={updateType}
                sx={{
                    minWidth: "160px",
                    height: "50px",
                }}>
                {Object.values(OrganisationType).map((type) => {
                    return (
                        type !== OrganisationType.None &&
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>)
}

export default OrganisationTypeSelect;