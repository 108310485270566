import React from "react";

import { Stack } from "@mui/material";

import { useContentShareState } from "amazon-chime-sdk-component-library-react";

import ChimeControlBar from "./chime-control-bar";
import ContentShareTile from "./content-share-tile";
import FilteredVideoTileGrid from "./filtered-video-tile-grid";
import CornerLocalView from "./corner-local-view";
import { MeetingInfoContext } from "../../context/meeting-info-context";
import SendAnnotationMessage from "../../annotation/send-annotation-message";

interface NormalMeetingProps {
    viewHeight: number;
    viewWidth: number;
    remote: boolean;
    excludeSelf?: boolean;
}

const staticCss: React.CSSProperties =
{
    position: "absolute",
    display: "flex",
    bottom: "1rem",
    left: "1rem",
    width: "20vw",
    maxHeight: "30vh",
    height: "auto",
    zIndex: "100",
}

const NormalMeeting: React.FC<NormalMeetingProps> = ({
    viewHeight,
    viewWidth,
    remote,
    excludeSelf = true
}: NormalMeetingProps) => {

    const { sharingAttendeeId, isLocalUserSharing } = useContentShareState();
    const { userId } = React.useContext(MeetingInfoContext);
    const fullSizeScreenShare = sharingAttendeeId != undefined && !isLocalUserSharing;

    const { showAnnotation } = React.useContext(MeetingInfoContext);

    return (
        <>
            <Stack
                id={"normal-meeting-stack"}
                justifyContent={"center"}
                justifyItems={"center"}
                alignContent={"center"}
                alignItems={"center"}
                minHeight={`${viewHeight}vh`}>
                {sharingAttendeeId &&
                    <ContentShareTile
                        tileCount={1}
                        viewHeight={viewHeight}
                        viewWidth={viewWidth}
                        customCss={isLocalUserSharing ? staticCss : undefined} />}
                {!fullSizeScreenShare && !showAnnotation &&
                    <FilteredVideoTileGrid
                        viewHeight={viewHeight}
                        viewWidth={viewWidth}
                        showNamePlate
                        excludeSelf={excludeSelf} />}
                <SendAnnotationMessage userId={userId} />
            </Stack>
            <ChimeControlBar showText={false} startWithVideo={remote} contentShare={true} />
            <CornerLocalView
                viewHeight={viewHeight}
                viewWidth={viewWidth}
            />
        </>
    );
};

export default NormalMeeting;