import { Button, Stack, TextField, Typography } from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";

import { v4 as uuidv4 } from "uuid";

import { User, UserType } from "../../types";
import UpdateTypeSelect from "../update-type-select";

interface AddUserFormProps {
    organisationId: string;
    userType?: UserType;
    showEmail?: boolean;
    user?: User;
    addUser: (user: User) => void;
}

const AddUserForm: React.FC<AddUserFormProps> = ({
    organisationId,
    userType = UserType.User,
    user,
    showEmail = true,
    addUser,
}: AddUserFormProps) => {

    const userTypeOptions = [UserType.User];

    if (userType === UserType.SysAdmin) {
        userTypeOptions.push(UserType.OrgAdmin);
        userTypeOptions.push(UserType.SysAdmin);
    }

    if (userType === UserType.OrgAdmin) {
        userTypeOptions.push(UserType.OrgAdmin);
    }

    const validationSchema = {
        fullName: Yup.string()
            .required("Full Name is required"),
        jobTitle: Yup.string(),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        userType: Yup.string(),
    }

    const validationSchemaNoEmail = {
        fullName: Yup.string()
            .required("Full Name is required"),
        jobTitle: Yup.string(),
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: user?.email || "",
            fullName: user?.fullName || "",
            jobTitle: user?.jobTitle || "",
            userType: userTypeOptions.includes(user?.userType || UserType.User) ? user?.userType : UserType.User,
        },

        //require length above 0
        validationSchema: Yup.object(
            showEmail ? validationSchema : validationSchemaNoEmail
        ),
        onSubmit: (values) => {
            const { fullName, email, jobTitle, userType } = values;

            const createdUser: User = {
                userId: user?.userId || uuidv4(),
                fullName,
                email,
                jobTitle,
                organisationId: user?.organisationId || organisationId,
                userType: userType ? userType : user?.userType || UserType.User,
                permissionSet: false,
            }

            addUser(createdUser);
        },
    });

    const UpdateUserType = (userType: UserType) => {
        formik.setFieldValue("userType", userType);
    }

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            padding={2}>
            <form onSubmit={formik.handleSubmit} style={{
                width: "90%"
            }}>
                <Stack
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    width={"100%"}
                    spacing={2}>
                    <Typography variant={"h3"}>Add User</Typography>
                    <TextField
                        fullWidth
                        id="fullName"
                        name="fullName"
                        label="Full Name"
                        variant="outlined"
                        autoComplete="name"
                        value={formik.values.fullName}
                        onChange={formik.handleChange}
                        error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                        helperText={formik.touched.fullName && formik.errors.fullName}
                    />
                    <TextField
                        fullWidth
                        id="jobTitle"
                        name="jobTitle"
                        label="Job Title"
                        variant="outlined"
                        autoComplete="jobTitle"
                        value={formik.values.jobTitle}
                        onChange={formik.handleChange}
                        error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                        helperText={formik.touched.jobTitle && formik.errors.jobTitle} />
                    {showEmail &&
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            variant="outlined"
                            autoComplete="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />}
                    {showEmail &&
                        <UpdateTypeSelect
                            value={formik.values.userType || UserType.User}
                            onChange={(event) => UpdateUserType(event.target.value as UserType)}
                            options={userTypeOptions}
                            name={"userType"}
                            label={"User Type"}
                        />
                    }

                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Add User
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
}

export default AddUserForm;