import { Organisation } from "../types";

const uploadURL = import.meta.env.VITE_API_URL as string + "/organisations";

const listOrganisations = async (
    authToken: string
): Promise<Organisation[]> => {
    try {
        const response = await fetch(uploadURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const organisations: Organisation[] = data.map(
            (organisation: any) => {
                const currentOrganisation: Organisation = {
                    organisationId: organisation.organisationId,
                    name: organisation.name,
                    type: organisation.type,
                    color: organisation.color,
                    usersIds: organisation.usersIds,
                    siteIds: organisation.siteIds || [],
                };

                return currentOrganisation;
            });

        return organisations;
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const getOrganisation = async (
    authToken: string,
    organisationId: string
): Promise<Organisation | null> => {

    if (organisationId.length == 0) {
        return null;
    }

    let response = null;
    try {
        response = await fetch(`${uploadURL}/${organisationId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

    } catch (error) {
        console.log(error);
        throw error as Error;
    }

    if (response.status === 404) {
        console.warn("Organisation not found");
        return null;
    }

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    const data = await response.json();

    if (!data) {
        return null;
    }

    const organisation: Organisation = {
        organisationId: data.organisationId,
        name: data.name,
        type: data.type,
        color: data.color,
        usersIds: data.usersIds,
        siteIds: data.siteIds || [],
    }

    return organisation;
}

const addOrganisation = async (
    authToken: string,
    organisation: Organisation
): Promise<string | Error> => {
    try {
        const response = await fetch(uploadURL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(organisation),
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const updateOrganisation = async (
    authToken: string,
    organisation: Organisation
): Promise<string | Error> => {
    try {
        const response = await fetch(`${uploadURL}/${organisation.organisationId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(organisation),
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const deleteOrganisation = async (
    authToken: string,
    organisation: Organisation
): Promise<string | Error> => {
    try {
        const response = await fetch(`${uploadURL}/${organisation.organisationId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const getOrganisationByIds = async (
    authToken: string,
    organisationIds: string[]
): Promise<(Organisation | null)[]> => {

    const organisations: (Organisation | null)[] = [];
    for (let i = 0; i < organisationIds.length; i++) {
        const user = await getOrganisation(authToken, organisationIds[i]);
        organisations.push(user);
    }

    return organisations;
}

const OrganisationAPIService = {
    listOrganisations,
    getOrganisation,
    addOrganisation,
    updateOrganisation,
    deleteOrganisation,
    getOrganisationByIds,
};

export default OrganisationAPIService;