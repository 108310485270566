import React from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";

import { useFormik } from "formik";

import { Organisation, OrganisationType, validField } from "../../types";

import OrganisationColorSelect, { findColor } from "./organisation-color-select";
import OrganisationTypeSelect from "./organisation-type-select";

interface EditOrganisationModalProps {
    updateOrganisation: (organisation: Organisation) => void;
    organisation: Organisation;
    create: boolean;
}

const EditOrganisationForm: React.FC<EditOrganisationModalProps> = ({
    organisation,
    updateOrganisation,
    create,
}) => {

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            organisationId: organisation.organisationId,
            name: organisation.name,
            type: organisation.type,
            color: findColor(organisation.color),
        },
        validate: values => {
            const errors: Partial<Record<keyof typeof values, string>> = {};

            if (!validField(values.name)) {
                errors.name = "Name must not contain special characters";
            }

            if (values.name.length < 3) {
                errors.name = "Name must be at least 3 characters";
            }
            if (values.name.length > 32) {
                errors.name = "Name must be at most 32 characters";
            }

            return errors;
        },
        onSubmit: values => {
            const updatedOrganisation: Organisation =
            {
                organisationId: values.organisationId,
                name: values.name,
                type: values.type,
                color: values.color,
                usersIds: organisation.usersIds,
                siteIds: organisation.siteIds,
            }

            updateOrganisation(updatedOrganisation);
            closeModal();
        }
    });


    const closeModal = () => {
        formik.resetForm();
    }

    const submitDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;

    // form component is required for formik to work
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                direction={"row"}
                container
                spacing={2}
                paddingY={1}
                justifyContent={"left"}
                alignItems={"center"}
                width={"100%"}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        variant="outlined"
                        autoComplete="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    {formik.touched.name && formik.errors.name &&
                        <Typography color="error">{formik.errors.name as string}</Typography>}
                </Grid>
                <Grid item xs={6}>
                    <OrganisationColorSelect selectedColor={formik.values.color} onSelectColor={formik.handleChange} />
                </Grid>
                <Grid item xs={6}>
                    <OrganisationTypeSelect selectedType={formik.values.type} updateType={formik.handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={submitDisabled}
                        color="success" type="submit"
                        variant="outlined"
                        sx={{
                            width: "fit-content",
                            alignSelf: "center"
                        }}>
                        {create ? "Create" : "Update"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default EditOrganisationForm;