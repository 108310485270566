import React from "react";
import {
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { AuthContext } from "./context/auth-context";
import {
    Meeting, MeetingJoinInfo, ResourceType, Organisation, Patient, User, UserType,
    Site,
} from "./types";

import MeetingsAPIService from "./services/meetings-api-service";

import UsersServiceAPI from "./services/users-service";
import OrganisationAPIService from "./services/organisation-api-service";
import PatientService from "./services/patient-service";
import SiteAPIService from "./services/site-service";
import { uploadFileAndCreateDownloadURL } from "./services/upload-service";
import { joinMeeting } from "./services/chime-service";
import { MeetingManager } from "amazon-chime-sdk-component-library-react";

//////////// Meetings ////////////
export const useMeetingList = (startRange?: Date, endRange?: Date): UseQueryResult<Meeting[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Meetings],
        queryFn: async (): Promise<Meeting[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }

            return MeetingsAPIService.listMeetings(authToken, startRange, endRange)
        },
        initialData: [],
    });
};

export const useGetMeeting = (meetingId: string): UseQueryResult<Meeting | null> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Meetings, meetingId],
        queryFn: async (): Promise<Meeting | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.getMeeting(authToken, meetingId)
        },
    });
}

export const useGetMeetingsWithIds = (meetingIds: string[]): UseQueryResult<(Meeting | null)[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Meetings, meetingIds],
        queryFn: async (): Promise<(Meeting | null)[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }
            return MeetingsAPIService.getMeetingsWithIds(authToken, meetingIds, true)
        },
    });
}

export const useAddMeeting = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.addMeeting(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Meetings] });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
};

export const useAddTrainingMeeting = (onSuccess?: (meeting: Meeting) => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.addTrainingMeeting(authToken, values);
        },
        onSuccess: (data: any) => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Meetings] });
            if (onSuccess) {
                onSuccess(data);
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
}

export const useDeleteMeeting = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (meeting: Meeting) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.deleteMeeting(authToken, meeting);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Meetings] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    }) as UseMutationResult;
};

export const useUpdateMeeting = (onSuccess?: () => void, onError?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.updateMeeting(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Meetings] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

export const useUpdateAttendees = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.updateAttendees(authToken, values.meetingId, values.attendeesId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Meetings] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
};

//////////// PhotoUrl ////////////

// Can return null so we can tell the difference between no data and no photoUrls
export const useGetPhotoUrls = (userId: string, meetingId: string): UseQueryResult<{
    photoUrls: string[],
    modelUrls: string[],
} | null> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Uploads, meetingId],
        queryFn: async (): Promise<{
            photoUrls: string[],
            modelUrls: string[],
        } | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.getPhotoUrls(authToken, userId, meetingId)
        },
        initialData: null,
    });
}

export const useUploadFile = (meetingId: string, userId: string, patientId: string, onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {
            return uploadFileAndCreateDownloadURL(values, userId, meetingId, patientId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Uploads, meetingId] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
};

//////////// Users ////////////
export const useGetUser = (userId: string): UseQueryResult<User> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Users, userId],
        queryFn: async (): Promise<User | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return UsersServiceAPI.getUser(authToken, userId)
        }
    });
}

export const useListUsers = (): UseQueryResult<User[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    return useQuery({
        queryKey: [ResourceType.Users],
        queryFn: async (): Promise<User[] | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.listUsers(authToken)
        },
    });
}

export const useGetUsersWithIds = (userIds: string[], showDeleted: boolean): UseQueryResult<(User | null)[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    return useQuery({
        queryKey: [ResourceType.Users, userIds],
        queryFn: async (): Promise<(User | null)[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }
            return UsersServiceAPI.getUsersWithIds(authToken, userIds, showDeleted)
        },
    });
}

export const useGetCurrentUser = (): UseQueryResult<User> => {
    const { userId } = React.useContext(AuthContext);
    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Users, userId],
        queryFn: async (): Promise<User | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.getUser(authToken, userId)
        },
    });
}

export const useUpdateUser = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return UsersServiceAPI.updateUser(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Users] });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
}

export const useTransferUserOrganisation = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return UsersServiceAPI.transferUser(authToken, values.userId, values.organisationId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Users] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
}

export const useAddTemporaryUser = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return UsersServiceAPI.addTemporaryUser(authToken, values);
        },
        onSuccess: (data: any, variables: any) => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Users] });

            if (variables?.organisationId) {
                queryClient.invalidateQueries({ queryKey: [ResourceType.Organisation, variables.organisationId] });
            }

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
}

export const useAdminAddUser = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.adminAddUser(authToken, values);
        },
        onSuccess: (data: any, variables: any) => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Users] });

            if (variables?.organisationId) {
                queryClient.invalidateQueries({ queryKey: [ResourceType.Organisation, variables.organisationId] });
            }

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
}

export const useAdminDeleteUser = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (user: User) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.adminDeleteUser(authToken, user.userId);
        },
        onSuccess: (data: any, variables: any) => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Users] });

            if (variables?.organisationId) {
                queryClient.invalidateQueries({ queryKey: [ResourceType.Organisation, variables.organisationId] });
            }

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    }) as UseMutationResult;
}

export const useAdminUpdateUser = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.adminUpdateUserType(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Users] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
}

//////////// Organisation ////////////

export const useAddOrganisation = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return OrganisationAPIService.addOrganisation(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Organisation] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        }
    });
}

export const useListOrganisations = (): UseQueryResult<Organisation[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Organisation],
        queryFn: async (): Promise<Organisation[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }
            return OrganisationAPIService.listOrganisations(authToken)
        },
        initialData: [],
    });
}

export const useGetOrganisation = (organisationId: string): UseQueryResult<Organisation | null> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Organisation, organisationId],
        queryFn: async (): Promise<Organisation | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return OrganisationAPIService.getOrganisation(authToken, organisationId)
        },
    });
}

export const useUpdateOrganisation = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return OrganisationAPIService.updateOrganisation(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Organisation] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
}

export const useDeleteOrganisation = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (organisation: Organisation) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return OrganisationAPIService.deleteOrganisation(authToken, organisation);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Organisation] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        }
    }) as UseMutationResult;
}

export const useGetOrganisationByIds = (organisationIds: string[]): UseQueryResult<(Organisation | null)[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Organisation, organisationIds],
        queryFn: async (): Promise<(Organisation | null)[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return []
            }

            return OrganisationAPIService.getOrganisationByIds(authToken, organisationIds)
        },
    });
}


//////////// Patient ////////////

export const useListPatients = (): UseQueryResult<Patient[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Patients],
        queryFn: async (): Promise<Patient[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }

            return PatientService.listPatients(authToken)
        },
        initialData: [],
    });
};

export const useGetPatient = (patientId: string): UseQueryResult<Patient> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Patients, patientId],
        queryFn: async (): Promise<Patient | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return PatientService.getPatient(authToken, patientId)
        },
    });
}

export const useAddPatient = (onSuccess?: (patient: Patient) => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return PatientService.addPatient(authToken);
        },
        onSuccess: (response: any) => {
            queryClient.invalidateQueries({ queryKey: [ResourceType.Patients] });

            if (onSuccess) {
                onSuccess(response);
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
};

//////////// Join Info ////////////

export const useGetJoinInfo = (meetingId: string): UseQueryResult<MeetingJoinInfo | null> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Chime, meetingId],
        queryFn: async (): Promise<MeetingJoinInfo | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.getJoinedAttendees(authToken, meetingId)
        },
    });
}

//////////// Site ////////////

export const useGetSite = (siteId: string): UseQueryResult<Site | null> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Site, siteId],
        queryFn: async (): Promise<any> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
            }
            return SiteAPIService.getSite(authToken, siteId)
        },
    });
}

export const useListSites = (): UseQueryResult<Site[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Site],
        queryFn: async (): Promise<any> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
            }
            return SiteAPIService.listSites(authToken)
        },
    });
}

export const useAddSite = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
            }
            return SiteAPIService.addSite(authToken, values);
        },
        onSuccess: (data: any, site: any) => {

            queryClient.invalidateQueries({ queryKey: [ResourceType.Site] });

            if (site) {
                for (const organisationId of site.linkedOrganisations) {
                    queryClient.invalidateQueries({ queryKey: [ResourceType.Organisation, organisationId] });
                }
            }
            else {
                console.error("Could not parse site from variables");
            }

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
};

export const useUpdateSite = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
            }
            return SiteAPIService.updateSite(authToken, values);
        },
        onSuccess: () => {

            queryClient.invalidateQueries({ queryKey: [ResourceType.Site] });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
};

export const useDeleteSite = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (site: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
            }
            return SiteAPIService.deleteSite(authToken, site);
        },
        onSuccess: (data: any, site: any) => {

            queryClient.invalidateQueries({ queryKey: [ResourceType.Site] });

            if (site) {
                for (const organisationId of site.linkedOrganisations) {
                    queryClient.invalidateQueries({ queryKey: [ResourceType.Organisation, organisationId] });
                }
            }
            else {
                console.error("Could not parse site from variables");
            }

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
};

export const useGetSiteByIds = (siteIds: string[]): UseQueryResult<(Site | null)[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: [ResourceType.Site, siteIds],
        queryFn: async (): Promise<any> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
            }
            return SiteAPIService.getSiteByIds(authToken, siteIds);
        },
    });
};

//////////// Chime ////////////

export const useJoinMeeting = (meetingManager: MeetingManager, onSuccess: (joinInfo: any) => void, onFailure: (err: any) => void): UseMutationResult => {

    return useMutation({
        mutationFn: async (values: any) => {

            if (meetingManager == null) {
                return null;
            }

            return joinMeeting(meetingManager, values.meetingId, values.userId, values.meetingType);
        },
        onSuccess: (data: any) => {
            onSuccess(data);
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
};