import { useState } from "react";

import { SelectChangeEvent, Stack } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

import TelehealthIconButton from "../telehealth-icon-button";
import OrganisationTypeSelect from "./organisation-type-select";
import { OrganisationType } from "../../types";

interface StandaloneOrganisationTypeSelectProps {
    selectedType: OrganisationType;
    updateType: (newType: OrganisationType) => void;
}

const StandaloneOrganisationTypeSelect: React.FC<StandaloneOrganisationTypeSelectProps> = ({
    selectedType,
    updateType,
}) => {

    const [type, setType] = useState<OrganisationType>(selectedType);

    const handleTypeChange = (event: SelectChangeEvent<string>) => {
        setType(event.target.value as OrganisationType);
    }

    const confirmChange = () => {
        updateType(type);
    }

    const canSave = type !== selectedType;

    return (
        <Stack
            direction={"row"}
            spacing={0.5}
            alignContent={"center"}
            justifyContent={"center"}>

            <OrganisationTypeSelect
                selectedType={selectedType}
                updateType={handleTypeChange} />
            <Stack width={"100%"} justifyContent={"end"} paddingY={0.5}>
                <TelehealthIconButton
                    onClick={confirmChange}
                    disabled={!canSave}
                    color="primary"
                    icon={<SaveIcon />}
                    tooltip={canSave ? "Save changes" : "No changes to save"}
                />
            </Stack>
        </Stack>)
}

export default StandaloneOrganisationTypeSelect;