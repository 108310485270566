import React from "react";
import { Drawing } from "./send-annotation-message";

interface DrawingLineProps {
    keyProp: string
    drawing: Drawing
    userId: string
    width: number
    height: number
    showMouse: boolean
    scale: number;
    translateX: number;
    translateY: number;
    isDragging: boolean;
    lineIndex: number
}

const DrawingLine: React.FC<DrawingLineProps> = ({
    drawing,
    userId,
    width,
    height,
    keyProp,
    showMouse,
    translateX,
    translateY,
    scale,
    isDragging,
    lineIndex,
}) => {

    const adjustedTranslateX = translateX / scale;
    const adjustedTranslateY = translateY / scale;

    const getPoints = (drawing: Drawing) => {

        if (!drawing) {
            return [];
        }

        if (!drawing.points) {
            return [];
        }

        if (lineIndex < 0 || drawing.points.length >= lineIndex) {
            return drawing.points[lineIndex];
        }
    }

    const pointsToUse = getPoints(drawing);

    const line = (pointsToUse || []).map((p) => {
        return {
            x: ((p.x * width) + adjustedTranslateX),
            y: (p.y * height) + adjustedTranslateY,
        }
    })

    // Convert points to SVG format
    const points = line.map((p) => `${p.x},${p.y}`).join(' ');

    const scaledMouseX = (drawing?.userMouse?.x || 0) * width;
    const scaledMouseY = (drawing?.userMouse?.y || 0) * height;

    return (
        <div
            key={`drawingDiv${keyProp}`}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,
            }}
        >
            <svg
                width="100%"
                height="100%"
                key={`svg${userId}`}
                style={{
                    zIndex: 100,
                    transform: `scale(${scale})`,
                    transition: isDragging ? 'none' : 'transform 0.1s ease-out',
                }}>
                {drawing.userMouse && showMouse &&
                    <circle key={`${userId}$mouse`} cx={scaledMouseX} cy={scaledMouseY} r="10" fill={drawing?.color} />}
                <polyline points={points} stroke={drawing?.color} fill="none" strokeWidth="2" />
                {line.map((p, index) => (
                    <circle key={`${userId}${index}`} cx={p.x} cy={p.y} r="1" fill={drawing?.color} />
                ))}
            </svg>
        </div>
    );
};

export default DrawingLine;